import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Header from "../components/sections/header"
import Features from "../components/sections/features"
import Footer from "../components/sections/footer"
import GetStarted from "../components/sections/getstarted"

const IndexPage = () => (
  <Layout>
    <SEO title="Privacy" />
    <Navigation />
    <div style={{ paddingLeft: "5rem", paddingTop: "6rem" }}>
      <p class="c15">
        <span class="c18">CAPTURED LLC PRIVACY POLICY</span>
      </p>
      <p class="c7">
        <span class="c19"></span>
      </p>
      <p class="c15">
        <span class="c21">
          READ THIS PRIVACY POLICY IN ITS ENTIRETY BEFORE USING THE CAPTURED
          APP, ANY OF ITS FEATURES OR TRANSMITTING ANY INFORMATION OR MATERIAL
          (“CONTENT”) TO AND FROM THE CAPTURED APP.
        </span>
      </p>
      <p class="c7">
        <span class="c4"></span>
      </p>
      <p class="c15">
        <span class="c2">
          This Privacy Policy governs your access and/or use of the CAPTURED App
          developed by Captured LLC (“Company”) and the transmission of Content
          to and from the Captured App (“Situation”).
        </span>
      </p>
      <a id="t.e829c5eb732f9dda8cbc7ddec610ae1b78e2468d"></a>
      <a id="t.0"></a>
      <table class="c1">
        <tbody>
          <tr class="c6">
            <td class="c8" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c3"></span>
              </p>
            </td>
            <td class="c8 c10" colspan="1" rowspan="1">
              <p class="c5">
                <span class="c2">
                  In order to use the Captured App, you must click on the box
                  provided below confirming your agreement to this Policy and
                  your authorization and consent for Company to access, preserve
                  and disclose your Content. If you are unwilling to accept the
                  terms and conditions of this Privacy Policy, you may not use
                  the Captured App to transmit your Content to contacts that you
                  have identified (“Guardiansˮ) or to law enforcement agencies.
                  When you submit and / or transmit Content via the Captured
                  App, you consent to the collection, use and disclosure of that
                  information in accordance with this Privacy Policy.
                </span>
              </p>
              <p class="c5">
                <span class="c2">
                  Captured LLC reserves the right to occasionally update this
                  Privacy Policy. With each update, Company will revise the
                  “last updated” date at the end of this Privacy Policy. For
                  material changes to this Privacy Policy, Company will notify
                  you by placing a notice on the Captured App. We recommend that
                  you periodically review this Privacy Policy.
                </span>
              </p>
              <span class="c17">REQUIRED ACTION</span>
              <span class="c2">
                AS A CONDITION TO YOUR USE OF THE CAPTURED APP AND ITS MESSAGING
                SERVICES, YOU MUST CLICK ON THE BELOW BOXES AGREEING TO THESE
                TERMS OF USE AND AUTHORIZING AND CONSENTING TO DISCLOSURE OF
                CONTENT FOR PURPOSES SET FORTH BELOW.{" "}
              </span>
              <p class="c5">
                <span class="c3">□ I agree to these Terms of Use</span>
              </p>
              <p class="c5">
                <span class="c3">
                  □ You hereby authorize and consent to Company accessing,
                  preserving, and disclosing your Content if required to do so
                  by law or in good faith believe that such disclosure is
                  reasonably necessary to:
                </span>
              </p>
              <p class="c5">
                <span class="c3">(1) comply with the legal process; or</span>
              </p>
              <p class="c5">
                <span class="c3">(2) enforce these Terms of Use; or</span>
              </p>
              <p class="c5">
                <span class="c3">
                  (3) respond to a claim that any Content violates the rights of
                  third parties; or
                </span>
              </p>
              <p class="c5">
                <span class="c3">
                  (4) protect the rights, property or personal safety of the
                  users of the Captured App or the public.
                </span>
              </p>
              <p class="c0">
                <span class="c3"></span>
              </p>
              <h2 class="c9" id="h.bf7w0sf5qlet">
                <span class="c17">
                  1. INFORMATION THE COMPANY COLLECTS FROM YOU
                </span>
              </h2>
              <p class="c5">
                <span class="c3">
                  As part of the registration process, you will be asked to
                  provide information including an email address, your name, and
                  contact phone numbers and contact information for Guardians.
                </span>
              </p>
              <p class="c5">
                <span class="c3">
                  When you use the Captured App, Company will not collect any
                  personally identifiable information about you unless you
                  provide it to us voluntarily.
                </span>
              </p>
              <p class="c5">
                <span class="c3">
                  When you use the Captured App to transmit any information
                  pertaining to an Situation (Content, including audio, text and
                  other data), Company will collect such information.
                </span>
              </p>
              <p class="c5">
                <span class="c3">
                  Unless you disable the GPS-location functionality of the
                  Captured App or your mobile device (which will degrade our
                  ability to provide accurate and timely service), the Captured
                  App will identify your location (when the Captured App is
                  activated and the GPS service on the phone is enabled).
                </span>
              </p>
              <h2 class="c9" id="h.s1sqkxl650ei">
                <span class="c17">
                  2. HOW THE COMPANY MAY USE AND DISCLOSE INFORMATION OBTAINED
                  THROUGH THE CAPTURED APP
                </span>
              </h2>
              <p class="c5">
                <span class="c3">
                  If you elected to use the Captured App, during the setup
                  process you will be asked to identify Guardians to receive
                  Situation reports you elect to transmit through the Captured
                  App. Accordingly, when you utilize the Captured App to
                  transmit content, you agree that all Content in the Situation
                  reports will be made available to and accessible by Company
                  and the Guardians and you have no expectation of privacy in
                  connection therewith. Company is not responsible for whether
                  or how Guardians use the Content you transmit to them.{" "}
                </span>
              </p>
              <p class="c5">
                <span class="c3">
                  Your contact information (e.g., e-mail addresses, phone
                  number) will only be used by Company for the purposes for
                  which you supplied it to Company (e.g., responding to your
                  comments or requests for information, sending requested
                  newsletters, etc.).
                </span>
              </p>
              <p class="c5">
                <span class="c3">
                  Except as otherwise stated, Company may use Content internally
                  to operate the services described on the Captured App, to
                  improve the Captured App, to customize the Captured App to
                  your preferences, to communicate information to you, for
                  research purposes and for any other purpose specified in this
                  policy. In addition, we may make full use of all information
                  acquired through the Captured App that is not in personally
                  identifiable form.
                </span>
              </p>
              <p class="c5">
                <span class="c3">
                  In addition to the disclosure of your information to
                  Guardians, first responders and law enforcement agencies as
                  described above, you hereby authorize and consent to Company
                  disclosure and use of your Content:
                </span>
              </p>
              <p class="c5">
                <span class="c3">
                  (a) To contractors used by Company to support our business
                  (such as hosting providers, technical support, web analytics
                  and financial institutions), in which case we will require
                  such third parties to agree to use it for the same purposes;
                </span>
              </p>
              <p class="c5">
                <span class="c3">
                  (b) In the event of a sale, merger, liquidation, dissolution,
                  reorganization, assignment or other transfer of Company or the
                  business of the Captured App to which the information relates,
                  in which case Company will require any such buyer to agree to
                  treat it in accordance with this Privacy Policy and use it for
                  the same purposes;
                </span>
              </p>
              <p class="c5">
                <span class="c3">
                  (c) To respond to law enforcement requests and subpoenas,
                  where Company believes in good faith that such response is
                  required by applicable laws, court orders, or government
                  regulations;
                </span>
              </p>
              <p class="c5">
                <span class="c3">(d) To enforce the Terms of Use;</span>
              </p>
              <p class="c5">
                <span class="c3">
                  (e) To respond to a claim that any Content violates the rights
                  of third parties; and
                </span>
              </p>
              <p class="c5">
                <span class="c3">
                  in situations involving threats to the physical safety of any
                  person, including other users of the Captured App
                </span>
              </p>
              <p class="c5">
                <span class="c3">
                  Company does not otherwise distribute to third parties your
                  personally identifiable information. Company, however, will
                  have no liability for disclosure of information obtained due
                  to errors in transmission or the unauthorized intervention of
                  third parties.{" "}
                </span>
              </p>
              <p class="c5">
                <span class="c3">
                  Company may de-identify and aggregate your personal
                  information (including, without limitation, usage statistics
                  and geo-location information) with information from other
                  users of the Captured App. Company may use or disclose such
                  aggregated information for purposes of operating, modifying,
                  improving, promoting or otherwise commercializing the Captured
                  App or the aggregate data, but in no event shall such
                  aggregated information include any personally identifying
                  information.{" "}
                </span>
              </p>
              <h2 class="c9" id="h.bqgl0if4r5y2">
                <span class="c17">
                  3. CHOICES YOU HAVE ABOUT HOW THE COMPANY USES YOUR
                  INFORMATION
                </span>
              </h2>
              <p class="c5">
                <span class="c3">
                  Company strives to provide you with choices regarding the
                  personal information you provide to Company. You may modify
                  the personal information on your account at any time. However,
                  such modifications or deletions will only affect reports after
                  the date of such modifications. Any data associated with prior
                  Situation reports will remain unmodified as previously
                  provided to your Guardians, to first responders and law
                  enforcement agencies, and stored in our servers. You also have
                  control over which Guardians will receive your Situation
                  reports and what profile data you elect to include and make
                  accessible.
                </span>
              </p>
              <h2 class="c9" id="h.d759fmqsh1fz">
                <span class="c17">
                  4. HOW THE COMPANY PROTECTS YOUR INFORMATION
                </span>
              </h2>
              <p class="c5">
                <span class="c3">
                  Company will take reasonable measures to protect your personal
                  information Company uses both technical and procedural methods
                  to maintain the integrity and security of our databases,
                  including the use of encryption and limited access to data.
                  But you should keep in mind that no Internet transmission is
                  ever completely secure or error-free. PLEASE NOTE: The safety
                  and security of your information also depends on you. Never
                  share your user name or password with anyone else, notify us
                  promptly if you believe your user name or password security
                  has been breached, and remember to log off of the Captured App
                  before you leave your computer or mobile device.{" "}
                </span>
              </p>
              <h2 class="c9" id="h.rgap8f8pu62v">
                <span class="c17">5. OPT-OUT RIGHTS AND DATA RETENTION</span>
              </h2>
              <p class="c5">
                <span class="c3">
                  You can stop use of the Captured App by uninstalling it for
                  your mobile device using the uninstall process that is
                  available on your device. Company may retain your personal
                  information for as long as you use the Captured App and for a
                  reasonable time thereafter. If you would like for Company to
                  delete any of your personal information that you have provided
                  with this Captured App, please contact Company using the
                  contact information provided below.
                </span>
              </p>
              <h2 class="c9" id="h.c0ocll5pwm66">
                <span class="c17">
                  6. CHILDREN’S ONLINE PRIVACY PROTECTION ACT (COPPA)
                </span>
              </h2>
              <p class="c5">
                <span class="c3">
                  Company does not use the Captured App to knowingly solicit
                  data from or market to children under the age of eighteen
                  (18). If a parent or guardian becomes aware that his or her
                  child under the age of eighteen (18) has provided Company with
                  personal information without their consent, he or she should
                  contact Company using the contact information provided below.
                  Company will delete such information from its files within a
                  reasonable time. Further, if you are age seventeen (17) or
                  younger, you must have the permission of your parent or
                  guardian to use the Captured App.
                </span>
              </p>
              <h2 class="c9" id="h.kfzte07zrv2b">
                <span class="c17">7. YOUR CONSENT</span>
              </h2>
              <p class="c5">
                <span class="c3">
                  By using the Captured App, you are consenting to processing of
                  your personal information as set forth in this Privacy Policy
                  now and as amended. “Processing” means using cookies on a
                  computer/hand held device or using or accessing information in
                  any way, including, but not limited to, collecting, storing,
                  deleting, using, combining and disclosing information, all of
                  which activities will take place in the United States. If you
                  reside outside the United States, your information will be
                  transferred, processed and stored under United States privacy
                  standards unless such standards are inconsistent with the
                  privacy standards of the country where you reside.
                </span>
              </p>
              <h2 class="c9" id="h.sar951r18t69">
                <span class="c17">8. HOW YOU CAN CONTACT THE COMPANY</span>
              </h2>
              <p class="c5">
                <span class="c3">
                  If you have any questions, comments or concerns about this
                  Privacy Policy or the information practices of the Captured
                  App, please contact us at info@capturednow.com
                </span>
              </p>
              <p class="c5">
                <span class="c3">We look forward to your communications.</span>
              </p>
              <h3 class="c13" id="h.xy8hi1i05l4j">
                <span class="c2"></span>
              </h3>
              <h3 class="c13" id="h.vo3y8pumlre4">
                <span class="c2"></span>
              </h3>
              <h3 class="c13" id="h.6gng3kjf7voo">
                <span class="c2"></span>
              </h3>
              <h3 class="c20" id="h.sh6oukoetluv">
                <span class="c2">Contact Information</span>
              </h3>
              <h4 class="c11" id="h.71g2sj2f0rae">
                <span class="c14">Captured LLC</span>
              </h4>
              <p class="c5">
                <span class="c3">Duxbury, MA 02332</span>
              </p>
              <h4 class="c11" id="h.xpz4ha2es7kb">
                <span class="c14">Email: info@capturednow.com</span>
              </h4>
            </td>
            <td class="c8" colspan="1" rowspan="1">
              <p class="c0">
                <span class="c3"></span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="c7">
        <span class="c3"></span>
      </p>
      <p class="c16">
        <span class="c3"></span>
      </p>
    </div>
    <Footer />
  </Layout>
)

export default IndexPage
